@import "~antd/dist/antd.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.text-xs {
  font-size: 11px;
}

.text-s {
  font-size: 12px;
}

.centered {
  text-align: center;
}

#root {
  height: 100%;
}

.wh100 {
  width: 100%;
}

.ht100 {
  height: 100%;
}

.panel-content {
  background-color: white;
}

.sidebar_network-logo {
  width: 5px;
  height: 5px;
  margin-right: 10px;
}

.table_validator-logo {
  width: 35px;
  height: 35px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.filter-green {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(318deg)
    brightness(103%) contrast(102%);
}
